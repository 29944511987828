import type { NowSellerDataForCart } from '@redteclab/api/clients/bully'

import type NowService from 'shared/services/api/NowService'
import store from 'shared/store'
import throttle from 'shared/utils/throttle'
import buildClientAPI from 'views/providers/clientAPI'

/**
 * NB! Remove NowService if not used elsewhere but in this experiment
 */

const FORM_SELECTOR = '[data-clientside-hook~="handleZipCodes"]'
const MSG_CLASSNAMES = new Map<boolean, string>([
  [true, 'o-SamedayDelivery__deliveryFormStatus--positive'],
  [false, 'o-SamedayDelivery__deliveryFormStatus--negative'],
])

class ZipCodeSearch {
  // There are few ZIP search input fields on the page
  #activeContainer?: HTMLElement

  readonly #forms: NodeListOf<HTMLElement>
  readonly #nowService: NowService
  readonly #throttleDelay = 200

  constructor() {
    this.#nowService = buildClientAPI().nowService
    this.#forms = document.querySelectorAll<HTMLElement>(FORM_SELECTOR)
  }

  setActiveContainer(element: HTMLElement) {
    this.#activeContainer = element
  }

  isValidEnv(): boolean {
    const { publicConfig } = store.getPublicRuntimeConfig() || {}
    const { nowWidget } = publicConfig

    return [nowWidget?.url, nowWidget?.shopName, nowWidget?.shopEnv].every(
      Boolean
    )
  }

  isValidZipCode(zip?: string): zip is string {
    return Boolean(zip?.match(/^\d{3,}$/))
  }

  showMessage({ msg, success = false }: { msg: string; success?: boolean }) {
    const container = this.#activeContainer?.querySelector(
      '.o-SamedayDelivery__deliveryFormStatus'
    )

    if (container) {
      container.innerHTML = msg ? `● ${msg}` : ''
      for (const c of MSG_CLASSNAMES.values()) {
        container.classList.remove(c)
      }

      container.classList.add(<string>MSG_CLASSNAMES.get(success))
    }
  }

  handleSearchResponse(data: NowSellerDataForCart) {
    if (data?.zipCodeInDeliveryRegion) {
      this.showMessage({
        msg: 'Sie befinden sich im Liefergebiet für eine NOW! Lieferung.',
        success: true,
      })
    } else {
      this.showMessage({
        msg: 'Leider befindet sich Ihre PLZ noch nicht im Liefergebiet für eine NOW! Lieferung. Wir arbeiten mit Hochdruck an der Erweiterung des Liefergebiets.',
      })
    }
  }

  resetSearch(zipCodeInput: HTMLInputElement) {
    zipCodeInput.value = ''
    this.showMessage({ msg: '' })
  }

  async handleZipSearch() {
    const zipCodeInput =
      this.#activeContainer?.querySelector<HTMLInputElement>('input[type=text]')
    if (!zipCodeInput) {
      return
    }

    const zipCode = zipCodeInput.value
    if (this.isValidZipCode(zipCode)) {
      const nowInfo = await this.#nowService.fetchBasicData({
        zipCode,
      })
      this.handleSearchResponse(nowInfo)
    } else if (!zipCode) {
      this.resetSearch(zipCodeInput)
    }
  }

  /**
   * Event handlers throttled, so no multiple simultaneous submits can be performed
   */
  bindFormControls() {
    this.#forms.forEach(form => {
      form.querySelector('button')?.addEventListener(
        'click',
        throttle((event: Event) => {
          event.preventDefault()
          this.setActiveContainer(form)

          void this.handleZipSearch()
        }, this.#throttleDelay)
      )

      form.querySelector('input[type=text]')?.addEventListener(
        'keyup',
        throttle((event: KeyboardEvent) => {
          if (event.code === 'Enter') {
            event.preventDefault()
            this.setActiveContainer(form)

            void this.handleZipSearch()
          }
        }, this.#throttleDelay)
      )
    })
  }

  init() {
    if (!this.isValidEnv()) {
      return
    }

    this.bindFormControls()
  }
}

export default () => new ZipCodeSearch().init()
